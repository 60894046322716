<template>
   <h1 class="mx-auto mt-10">У Вас недостаточно прав для доступа</h1>
</template>

<script>
export default {
	data () {
		return {}
	}
}
</script>
