<template>
   <div style="width:100%">
		<v-card block :loading="isLoading">
			<v-card-title>
				Управление новостями <v-chip label small class="ml-2">Всего: {{ total }}</v-chip>
                <v-spacer />
				<v-btn color="primary" @click="getdialogNews()" elevation="0">Добавить новость</v-btn>
			</v-card-title>
			<v-card-text>
				<v-data-table :headers="headers" :items="news" :items-per-page="countRows" class="elevation-1" hide-default-footer>
					<template v-slot:item.setting="{ item }">
						<v-btn small icon @click="openEditDialog( item.id )" :loading="btnLoadingEdit == item.id" color="teal" class="mr-1">
							<v-icon>mdi-circle-edit-outline</v-icon>
						</v-btn>
						<!-- <v-btn small icon @click="deleteNews( item.id )" color="red" class="mr-1">
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn> -->
                        <v-btn small icon @click="unpublish(item)" color="primary">
							<v-icon v-if="item.published" title="Скрыть новость">mdi-eye</v-icon>
							<v-icon v-else title="Опубликовать новость">mdi-eye-off</v-icon>
						</v-btn>
					</template> 
				</v-data-table>
				<div class="pt-4">
					<v-row justify="space-between">
						<v-col cols=2 align-self="center">
							<v-select :items="itemsCountRows" v-model="countRows" label="Кол-во записей" hide-details solo></v-select>
						</v-col>
						<v-spacer></v-spacer>
						<v-col align-self="center" class="pag-end">
							<v-pagination v-model="page" :length="totalPage" :total-visible="7"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>


        <v-dialog v-model="dialogNews" persistent max-width="90%">
			<v-card>
                <v-toolbar color="primary" dark class="mb-3">
					<span class="text-h5" v-if="createMode">Добавить Новость</span>
                    <span class="text-h5" v-if="editMode">Редактировать Новость</span>
				</v-toolbar>
                <v-card-text>
                    <v-row dense style="margin: 0 40px;">
                        <!-- Left -->
                        <v-col cols="6" style="border-right: 1px solid black; padding: 0 20px;">
                            <v-form
                                ref="dialogNewsRef"
                                v-model="validateDialogNews"
                                lazy-validation
                            >
                                <v-col cols="12">
                                    <v-text-field label="Дата*" :rules="rulesDialogNews" v-model="newNewsForm.date"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Заголовок*" :rules="rulesDialogNews" v-model="newNewsForm.name"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-textarea label="Текст новости*" outlined :rules="rulesDialogNews" v-model="newNewsForm.desc"></v-textarea> -->

                                    <quill-editor v-model="newNewsForm.desc" :options="editorOptions" />
                                
                                </v-col>

                                <v-col cols="12">
                                    <div class="mr-4 d-flex align-center">
                                        <div class="subtitle mr-3">Добавить видео</div>
                                        <v-btn class="primary" x-small @click="videos.push({url: '', name: ''})"><v-icon>mdi-plus</v-icon></v-btn>
                                    </div>

                                    <div v-for="item, index in videos" :key="index" class="mt-3">
                                        <v-row align="center">
                                            <v-col cols="5">
                                                <v-text-field label="Ссылка на видео (YouTube)*" :rules="rulesDialogNewsVideo" v-model="item.url" />
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field label="Подпись к видео" v-model="item.name" />
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn small icon @click="videos = videos.filter((i, tmpindex) => (tmpindex != index))" color="red" class="mr-1">
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>

                                <v-col cols="12">
                                    <div class="mr-4 d-flex align-center">
                                        <div class="subtitle mr-3">Добавить изображения:</div>
                                    </div>
                                    <v-file-input 
                                        label="Изображения" 
                                        multiple 
                                        v-model="tmpImages" 
                                        prepend-icon="mdi-file-image" 
                                        @change="handleAddImages()"
                                        counter
                                        :counter-string="getFilesCount()"
                                        accept="image/png, image/jpeg, image/jpg" 
                                    >
                                        <template v-slot:selection="">
                                            <v-chip small label color="primary" v-for="file, index in images" :key="index">
                                                {{ file.name.substr(0, 10) }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>

                                    <div class="d-flex align-center flex-wrap mt-2">
                                        <div v-for="img, index in images" :key="index" style="position: relative;" class="mr-1 mb-1">
                                            <v-img :src="img.url" width="200" :aspect-ratio="16/9" />
                                            <v-btn x-small fab @click="deleteImage(img.url)" color="white" class="delete_img">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-col>

                                <v-col cols="12">
                                    <div class="mr-4 d-flex align-center">
                                        <div class="subtitle mr-3">Добавить полезные ссылки</div>
                                        <v-btn class="primary" x-small @click="useful_links.push({url: '', name: ''})"><v-icon>mdi-plus</v-icon></v-btn>
                                    </div>

                                    <div v-for="item, index in useful_links" :key="index" class="mt-3">
                                        <v-row align="center">
                                            <v-col cols="5">
                                                <v-text-field label="Ссылка*" v-model="item.url" />
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field label="Текст*" v-model="item.name" />
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn small icon @click="useful_links = useful_links.filter((i, tmpindex) => (tmpindex != index))" color="red" class="mr-1">
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>

                                <v-col cols="12">
                                    <div class="mr-4 d-flex align-center">
                                        <div class="subtitle mr-3">Добавить файлы:</div>
                                    </div>
                                    <div class="mt-3">
                                        <v-file-input 
                                            label="Файлы" 
                                            multiple 
                                            v-model="tmpFiles" 
                                            prepend-icon="mdi-file" 
                                            :messages="['Имя файла будет отображаться на портале']" 
                                            @change="handleAddFiles()"
                                            counter
                                            :counter-string="getFilesCount()"
                                        >
                                            <template v-slot:selection="">
                                                <v-chip small label color="primary" v-for="file, index in files" :key="index">
                                                    {{ file.name.substr(0, 10) }}
                                                </v-chip>
                                            </template>
                                        </v-file-input>

                                        <div class="d-flex align-center flex-wrap mt-2">
                                            <div v-for="file, index in files" :key="index" class="show_file">
                                                {{ file.name.substr(0, 10) }}...
                                                <v-icon class="delete_file" @click="deleteFile(file.name)">mdi-close</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>

                                <v-col cols="12">   
                                    <span style="display: inline-block;">
                                        <v-checkbox
                                            v-model="publishNewNews"
                                            label="Опубликовать новость"
                                            color="primary"
                                            hide-details
                                        />
                                    </span>
                                </v-col>
                                <small>*поля обязательны для заполнения</small>
                            </v-form>
                        </v-col>
                        <!-- /Left -->

                        <!-- Right -->
                        <v-col cols="6" style="padding: 0 20px;">
                            <div class="news_date">{{newNewsForm.date ? newNewsForm.date : 'Дата'}}</div>
                            <div class="news_title">{{newNewsForm.name ? newNewsForm.name : 'Заголовок'}}</div>
                            
                            <div v-if="newNewsForm.desc">
                                <!-- {{newNewsForm.desc}} -->
                                <div v-html="newNewsForm.desc"  class="news_text_item" />
                                <!-- <div v-for="item, index in newNewsForm.desc.split('\n')" :key="index" class="news_text_item">
                                    {{item}}
                                </div> -->
                            </div>
                            <div v-else style="margin-bottom: 20px;">Текст новости</div>

                            <div v-for="v, index in videos" :key="index" class="mb-4">
                                <iframe 
                                    v-if="createMode"
                                    title='Youtube player'
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    :src="`https://youtube.com/embed/${v.url.split('?v=')[1]}?autoplay=0`"
                                    style="width: 100%; height: 300px;" 
                                />
                                 <iframe 
                                    v-if="editMode"
                                    title='Youtube player'
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    :src="v.url"
                                    style="width: 100%; height: 300px;" 
                                />
                                <p>{{v.name}}</p>
                            </div>

                            <div v-if="images && images.length" class="mb-4">
                                <div v-for="img, index in images" :key="index" style="margin-bottom: 20px">
                                    <img v-if="img.url" :src="img.url" style="width: 100%;" />
                                    <p>{{img.name}}</p>
                                </div>
                            </div>

                            <div v-if="useful_links && useful_links.length" class="mb-4">
                                <div class="subtitle_show">Полезные ссылки:</div>
                                <ul>
                                    <li v-for="url, index in useful_links" :key="index">
                                        <a :href="url.url" target="_black" class="show_a">{{url.name}}</a>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="files && files.length" class="mb-4">
                                <div class="subtitle_show">Файлы:</div>
                                <div class="d-flex align-center flex-wrap">
                                    <div v-for="f, index in files" :key="index" class="show_file">
                                        {{f.name}}
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <!-- /Right -->
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <!-- ToDo: clear data on close -->
                    <v-btn color="red darken-1" text @click="closeNewsDialog()" >
                        Закрыть
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="createNews()" v-if="createMode" :loading="loadingAddNews">
                        Добавить
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="updateNews(newNewsForm.id)" v-if="editMode" :loading="loadingAddNews">
                        Применить изменения
                    </v-btn>
                </v-card-actions>
			</v-card>
		</v-dialog>
   </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme


export default {
    components: {
        quillEditor
    },
	data () {
		return {
			isLoading : false,

			headers: [
				{ text: 'ID', align: 'start', sortable: true, value: 'id' },
				{ text: 'Заголовок', value: 'name' },
				{ text: 'Дата', value: 'date', sortable: false, width: '170px' },
				{ text: 'Управление', value: 'setting', align: 'right', sortable: false },
			],

			itemsCountRows : [
				12, 20, 50, 100, 500
			],

			news: [],

			total: 0,
			page: 1,
			totalPage: 1,
			countRows: 12,

			dialogNews: false,
            newNewsForm: {},
            videos: [],
            images: [],
            tmpImages: [],
            useful_links: [],
            files: [],
            tmpFiles: [],
            publishNewNews: false,
            validateDialogNews: true,
            rulesDialogNews: [ v => !!v || 'Поле обязательно для заполнения!' ],
            rulesDialogNewsVideo: [
                v => !!v || 'Поле обязательно для заполнения!',
                v => (v && v.indexOf("https://www.youtube.com/watch?v=") != -1) || 'Неверная ссылка на видео!'
            ],
            loadingAddNews: false,
            editorOptions: {
                placeholder: 'Текст новости*',
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'link'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ]
                },
            },

            createMode: false,
            editMode: false,

            filesToDeleteWhenEdit: {},

			btnLoadingEdit: 0,
			dataEditNews: {},
		}
	},
	watch: {
		'page': function(){
			if ( !this.isLoading )
				this.getNews()
		},
		'countRows': function(val) {
			if ( !this.isLoading ) {
				this.getNews()
				localStorage.setItem("countRows", parseInt(val))
			}
		},
        // 'files': function() {
		// 	console.log(this.files)
		// },
	},
	methods: {
        getFilesCount() {
            switch (this.files.length) {
                case 1:
                    return '1 файл'
                case 2:
                case 3:
                case 4:
                    return `${this.files.length} файла`
                default:
                    return `${this.files.length} файлов`
            }
        },

        handleAddFiles() {
            for (var f of this.tmpFiles){
                this.files.push({file: f, url: '', name: f.name})
            }
        },
        deleteFile(filename) {
            if (this.editMode) {
                let tmp = this.files.find((f) => (f.name == filename))
                this.filesToDeleteWhenEdit["files"] = this.filesToDeleteWhenEdit["files"] ? [...this.filesToDeleteWhenEdit["files"], tmp] : [tmp] 
            }
            this.files = this.files.filter((f) => (f.name != filename))
        },

        handleAddImages() {
            for (var f of this.tmpImages){
                this.images.push({file: f, url: URL.createObjectURL(f), name: f.name})
            }
        },
        
        deleteImage(fileurl) {
            if (this.editMode) {
                let tmp = this.images.find((f) => (f.url == fileurl))
                this.filesToDeleteWhenEdit["banners"] = this.filesToDeleteWhenEdit["banners"] ? [...this.filesToDeleteWhenEdit["banners"], tmp] : [tmp]

            }
            this.images = this.images.filter((f) => (f.url != fileurl))
        },

		// ? Получить информацию для редактирования 
		openEditDialog( id ){
			this.btnLoadingEdit = id
			this.$http.get(`news/${id}`).then((response) => {
				this.dataEditNews = response.data.item;

                this.newNewsForm = {
                    id: response.data.item.id,
                    date: response.data.item.date,
                    name: response.data.item.name,
                    desc: response.data.item.desc
                };
                this.videos = response.data.item.videos ? response.data.item.videos.map((v) => ({url: `https://youtube.com/embed/${v.youtubeID}?autoplay=0`})) : [];
                this.images = response.data.item.banners ? response.data.item.banners : [];
                this.useful_links = response.data.item.urls ? response.data.item.urls : [];
                this.files = response.data.item.files ? response.data.item.files : [];
                this.publishNewNews = response.data.item.published;

				this.dialogNews = true;
                this.createMode = false;
                this.editMode = true;

				setTimeout(() => {
					this.btnLoadingEdit = false
				},300)
			});
		},

        deleteFileItem(type, index) {
            if (type == 'images') {
                if (this.editMode) {
                    this.filesToDeleteWhenEdit["banners"] = this.filesToDeleteWhenEdit["banners"] ? [...this.filesToDeleteWhenEdit["banners"], this.images[index].url] : [this.images[index].url] 
                }
                this.images = this.images.filter((i, tmpindex) => (tmpindex != index))
            } else if (type == 'files') {
                if (this.editMode) {
                    this.filesToDeleteWhenEdit["files"] = this.filesToDeleteWhenEdit["files"] ? [...this.filesToDeleteWhenEdit["files"], this.files[index].url] : [this.files[index].url]
                }
                this.files = this.files.filter((i, tmpindex) => (tmpindex != index))
            }
        },

		// ? Удалить событие
		deleteNews( id ) {
			this.$toast.warning(`Новость #${id} не может быть удалена. Действие заблокировано!`);
		},

		getNews() {
			this.isLoading = true
			this.$http.get("news", { params: {
				count: this.countRows,
				page: this.page
			} }).then((response) => {
				this.news = response.data.items
				this.total = parseInt(response.data.options.total)
				this.page = parseInt(response.data.options.page)
				this.totalPage = parseInt(response.data.options.totalPage)
				setTimeout(() => {
					this.isLoading = false
				}, 200)
			});
		},

        getdialogNews() {
            this.dialogNews = true;
            this.createMode = true;
            this.editMode = false;
        },

        closeNewsDialog() {
            this.$refs.dialogNewsRef.resetValidation();
            this.dialogNews = false;

            this.newNewsForm = {};
            this.videos = [];
            this.images = [];
            this.useful_links = [];
            this.files = [];
            this.publishNewNews = false;

            this.createMode = false;
            this.editMode = false;
        },

        // createFileUrl(index) {
        //     this.images[index].url = URL.createObjectURL(this.images[index].file)
        // },

        uploadNewsFiles(newsId) {
            var files = new FormData();
            for (var i in this.files) {
                files.append(`files`, this.files[i].file)
            }
            for (var j in this.images) {
                files.append(`banners`, this.images[j].file)
            } 
            
            this.$http.post(`news/${newsId}/files`, files).then((response) => {
                if ( response.data.status ) {
                    console.log(response.data)
                } else {
                    this.$toast.error("Возникли ошибки при сохранении файлов или картинок, отредактируйте новость");
                }
            });
        },

        createNews() {
            if (this.$refs.dialogNewsRef.validate()) {
                this.$confirm.q(this.publishNewNews ? `Сохранить и опубликовать новость?` : `Сохранить новость без публикования?`, () => {
                    this.loadingAddNews = true;
                    this.newNewsForm = { ...this.newNewsForm, published: this.publishNewNews }
                    if (this.useful_links.length) { this.newNewsForm.urls = this.useful_links; }
                    if (this.videos.length) { this.newNewsForm.videos = this.videos.map((v) => ({youtubeID: v.url.split('?v=')[1]})); }
                    
                    this.$http.post(`news`, {
                        form: this.newNewsForm
                    }).then((response) => {
                        if ( response.data.status ) {
                            if (this.images.length || this.files.length) {
                                this.uploadNewsFiles(response.data.item.data.id);
                            }
                            this.$toast.open(this.publishNewNews ? 'Новость сохранена и опубликована' : 'Новость сохранена');
                            this.loadingAddNews = false;
                            this.dialogNews = false;
                            // ? Обновляем информацию в списке новостей 
                            setTimeout(() => {
                                this.getNews()
                            },100)
                        } else {
                            this.$toast.error(`Возникли ошибки при сохранении новости (${response.data.reason})`);
                        }
                    });
                }, () => {
                    this.$toast.warning("Действие отменено пользователем");
                })
            }
        },


        updateNews(id) {
            this.$confirm.q(this.publishNewNews ? `Сохранить изменения и опубликовать новость?` : `Сохранить изменения без публикования?`, () => {
                this.newNewsForm = { ...this.newNewsForm, published: this.publishNewNews }
                if (this.useful_links.length) { this.newNewsForm.urls = this.useful_links; }
                if (this.videos.length) { this.newNewsForm.videos = this.videos.map((v) => ({youtubeID: v.url.split('?v=')[1]})); }
                
                this.$http.put(`news/${id}`, {
                    form: this.newNewsForm, toDeleteFiles: this.filesToDeleteWhenEdit
                }).then((response) => {
					if ( response.data.status ) {

                        if (this.images.length || this.files.length) {
                            this.uploadNewsFiles(response.data.item.data.id);
                        }

						this.$toast.open(this.publishNewNews ? 'Новость сохранена и опубликована' : 'Новость сохранена');

						this.dialogNews = false;
						// ? Обновляем информацию в списке новостей 
						setTimeout(() => {
							this.getNews()
						},100)
					} else {
						this.$toast.error(`Возникли ошибки при сохранении новости`);
					}
				});
            }, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
        },

        unpublish(news) {
            this.$http.put(`news/${news.id}`, {
                form: {published: !news.published}
            }).then((response) => {
                if ( response.data.status ) {
                    news.published = !news.published;
                    this.$toast.open(news.published ? 'Новость опубликована' : 'Новость скрыта');
                } else {
                    this.$toast.error(`Произошла ошибка`);
                }
            });
        }

	},
	created(){
		const countRows = localStorage.getItem("countRows")
		if ( countRows && parseInt(countRows) > 0 ) {
			this.countRows = parseInt(countRows)
		}
		this.getNews()
	}
}
</script>

<style>
.link-email { color:#1A237E; text-decoration: none !important; border-bottom: 1px dashed #1A237E;}
.pag-end .v-pagination { justify-content: right !important; }

.news_date {
    line-height: calc(var(0.25rem)*6.4);
    font-size: calc(var(0.25rem)*5);
    letter-spacing: normal;
    color: black;
}
.news_title {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin: 20px 0;
    color: black;
}
.news_text_item {
    line-height: calc(var(0.25rem)*6.4);
    font-size: calc(var(0.25rem)*5);
    letter-spacing: normal;
    color: black;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 24px;
    color: black;
    /* margin-bottom: 15px; */
}

.subtitle_show {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
    color: black !important;
}

.show_a {
    color: black !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600 !important;
}

.show_file {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;

    padding: 20px 20px;
    max-width: 400px;

    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0.25rem;

    color: black;
    font-weight: 600;
}

.delete_file {
    position: absolute;
    top: -17px;
    right: -17px;
    cursor: pointer;
}
.delete_img {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

</style>