<template>
   <div style="width:100%">
		<v-card block :loading="isLoading">
			<v-card-title>
				Управление организациями <v-chip label small class="ml-2">Всего: {{ total }}</v-chip>
			</v-card-title>
			<v-card-text>
				<v-text-field label="Поиск организации" filled v-model="search" clearable></v-text-field>

				<v-data-table :headers="headers" :items="orgs" :items-per-page="countRows" class="elevation-1" hide-default-footer>
					<template v-slot:item.count_access="{ item }">
						<v-btn small elevation="0" color="indigo lighten-5" :to="{
							name: 'Users',
							query: {
								atype: 'School',
								aid: item.id
							}
						}">{{ item.count_access }}</v-btn>
					</template>
					<template v-slot:item.setting="{ item }">
						<v-btn small icon @click="openEditDialog( item.id )" :loading="btnLoadingEdit == item.id" color="teal" class="mr-2">
							<v-icon>mdi-circle-edit-outline</v-icon>
						</v-btn>
						<v-btn small icon @click="deleteOrg( item.id )" color="red">
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
					</template>

					<template v-slot:item.logo="{ item }">
						<img 
							height="41" width="41" class="mt-1"
							:src="item.gerb_org.indexOf('org_logo') != -1 ? `https://cdn.инклюзивноеобразование.рф/${item.gerb_org}` : item.gerb_org" 
						/>
					</template>


				</v-data-table>
				<div class="pt-4">
					<v-row justify="space-between">
						<v-col cols=2 align-self="center">
							<v-select :items="itemsCountRows" v-model="countRows" label="Кол-во записей" hide-details solo></v-select>
						</v-col>
						<v-spacer></v-spacer>
						<v-col align-self="center" class="pag-end">
							<v-pagination v-model="page" :length="totalPage" :total-visible="7"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>

		<v-dialog v-model="dialogEditOrg" max-width="1200px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Редактирование информации об организации #{{idEditOrg}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-form
						ref="editOrgForm"
						v-model="validateEditOrgForm"
						lazy-validation
						class="mt-5"
					>
						<v-row class="align-center mb-4">
							<v-col cols="1" @click="dialogEditLogo = true" >
								<v-badge overlap icon="mdi-circle-edit-outline" style="cursor: pointer">
									<img 
										height="71" width="71" class="mt-1"
										:src="dataEditOrg.gerb_org?.indexOf('org_logo') != -1 ? `https://cdn.инклюзивноеобразование.рф/${dataEditOrg.gerb_org}` : dataEditOrg.gerb_org" 
									/>
								</v-badge>
							</v-col>
							<v-col cols="11">
								<v-textarea
									filled
									label="Наименование организации (без организационно-правовой формы)"
									rows="2"
									auto-grow
									v-model="dataEditOrg.short_name"
									hide-details
									required
									:rules="rulesOrgEdit"
								/>
							</v-col>
						</v-row>

						<v-text-field
							label="Краткое наименование организации (аббревиатура)"
							outlined
							v-model="dataEditOrg.abbr"
							required
							:rules="rulesOrgEdit"
						/>

						<v-row>
							<v-col cols="6">
								<v-select
									:items="regions"
									v-model="dataEditOrg.regions_id"
									outlined
									item-text="title"
									item-value="id"
									label="Субъект организации"
									required
									:rules="rulesOrgEdit"
								></v-select>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Город организации"
									outlined
									v-model="dataEditOrg.city"
									required
									:rules="rulesOrgEdit"
								/>
							</v-col>
						</v-row>

						<v-text-field
							label="Адрес организации"
							outlined
							v-model="dataEditOrg.address"
							required
							:rules="rulesOrgEdit"
						/>

						<v-row>
							<v-col cols="6">
								<v-text-field
									label="Сайт организации"
									outlined
									v-model="dataEditOrg.site"
									hide-details
									required
									:rules="rulesOrgEdit"
								/>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Телефон организации"
									outlined
									v-model="dataEditOrg.phone"
									:messages="['При наличии нескольких номеров, вписать их через запятую']"
									hide-details
								></v-text-field>
								<!-- v-mask="'# (###) ###-##-##'" -->
							</v-col>
						</v-row>

						<v-row class="align-center">
							<v-col cols="8">
								<v-select
									:items="rumcs"
									v-model="dataEditOrg.rumcs_id"
									outlined
									item-text="title"
									item-value="id"
									label="РУМЦ"
									hide-details
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-checkbox
									v-model="dataEditOrg.is_rumc"
									label="Организация является РУМЦ-ем?"
									color="primary"
								/>
							</v-col>
						</v-row>

						<v-switch
							v-model="orgUchred"
							hide-details
							:label="orgUchred ? 'Государственный' : 'Негосударственный'"
						></v-switch>

						<h2 class="mt-5">
							Галерея
							<v-btn small icon color="teal" @click="openDialogGallery()">
								<v-icon>mdi-circle-edit-outline</v-icon>
							</v-btn>
						</h2>

						<!-- <h2 class="mt-5">
							Филиалы
							<v-btn small icon color="teal" @click="openDialogBranches()">
								<v-icon>mdi-circle-edit-outline</v-icon>
							</v-btn>
						</h2> -->
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="closeEditOrg()" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="saveEditOrg()">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Редактирование логотипа организации -->
		<v-dialog v-model="dialogEditLogo" max-width="800px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Редактировать логотип</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-row class="align-center">
						<v-col cols="3">
							<img 
								v-if="!loadingNewLogo && !newLogoUrl" height="102" width="102" class="mt-4"
								:src="dataEditOrg.gerb_org?.indexOf('org_logo') != -1 ? `https://cdn.инклюзивноеобразование.рф/${dataEditOrg.gerb_org}` : dataEditOrg.gerb_org" 
							/>
							<img v-if="!loadingNewLogo && newLogoUrl" height="102" width="102" :src="newLogoUrl" class="mt-4" />
							<v-progress-circular v-if="loadingNewLogo" indeterminate color="primary" :size="102" class="mt-4"  />
						</v-col>
						<v-col cols="9">
							<v-file-input 
								label="Загрузить новый логотип (.png)" 
								v-model="newLogo" 
								prepend-icon="mdi-file-image" 
								@change="handleNewLogo()"
								accept="image/png" 
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogEditLogo = false">
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="error darken-1" text @click="deleteLogo(idEditOrg)">
						Удалить
					</v-btn>
					<v-btn color="teal darken-1" text @click="saveNewLogo(idEditOrg)">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- / Редактирование логотипа организации -->


		<!-- Редактирование галереи -->
		<v-dialog v-model="dialogOrgGallery" max-width="800px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Галерея</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="d-flex align-center flex-wrap mt-5">
						<div v-for="item in dataEditOrg.gallery" :key="item.id" class="mr-3 mb-3">
							<div>
								<div style="position: relative;">
									<v-img :src="item.url"  :aspect-ratio="16/9"/>
									<div  class="delete_img">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn 
													color="red darken-1" icon v-bind="attrs" v-on="on"
													:loading="loadingDeleteGallery == item.id"
													@click="handleDeleteGallery(item)"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</template>
											<span>Удалить</span>
										</v-tooltip>
									</div>
								</div>

								<div class="d-flex align-end">
									<v-text-field label="Подпись" v-model="item.name" hide-details class="mt-2"/>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn 
												color="teal darken-1" text icon v-bind="attrs" v-on="on" 
												:loading="loadingEditGallery == item.id"
												@click="handleEditGallery(item)"
											>
												<v-icon>mdi-check</v-icon>
											</v-btn>
										</template>
										<span>Сохранить</span>
									</v-tooltip>
								</div>
							</div>
						</div>

						<div class="d-flex align-center justify-space-around px-2" style="width: calc(100%/3 - 10px);">
							<v-file-input 
								v-if="!loadingAddToGallery"
								accept="image/*" label="Добавить изображение"  prepend-icon="mdi-plus" 
								@change="addToGallery()" v-model="newGallery"
							/>
							<v-progress-circular v-if="loadingAddToGallery" indeterminate color="primary" :size="102"  />
						</div>
					</div>

				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary darken-1" text @click="dialogOrgGallery = false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- / Редактирование галереи -->

		<!-- Филиалы -->
		<v-dialog v-model="dialogBranches" max-width="800px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Филиалы</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<!-- <h1 v-if="Object.keys(dataEditOrg.branches_addresses).length == 0" class="d-flex justify-space-around mt-5">Филиалы не найдены</h1> -->
					<ul class="mt-5">
						<li v-for="item, index in dataEditOrg.branches_addresses" :key="index">
							{{item.full_name}}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary darken-1" text @click="dialogBranches = false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- / Филиалы -->
   </div>
</template>

<script>
// import axios from "axios";

export default {
	data () {
		return {
			isLoading : false,

			headers: [
				{ text: 'ID', align: 'start', sortable: true, value: 'id', },
				{ text: 'Логотип', align: 'center', sortable: false, value: 'logo', },
				{ text: 'Наименование', value: 'title' },
				{ text: 'Пользователей', value: 'count_access', align: 'center', sortable: false },
				{ text: 'Управление', value: 'setting', align: 'right', sortable: false },
			],

			search : '',
			itemsCountRows : [
				12, 20, 50, 100, 500
			],

			orgs: [],
			regions: [],
			rumcs: [],

			total: 0,
			page: 1,
			totalPage: 1,
			countRows: 12,

			dialogEditOrg: false,
			dataEditOrg: {},
			btnLoadingEdit: 0,
			idEditOrg: 0,
			validateEditOrgForm: true,
			dialogEditLogo: false,
			orgUchred: false,
			newLogo: null,
			newLogoUrl: null,
			loadingNewLogo: false,
			rulesOrgEdit: [ v => !!v || 'Поле обязательно для заполнения!' ],

			dialogOrgGallery: false,
			loadingEditGallery: 0,
			loadingDeleteGallery: 0,
			newGallery: null,
			loadingAddToGallery: false,

			dialogBranches: false,
		}
	},
	watch: {
		'page': function(){
			if ( !this.isLoading )
				this.getOrganizations()
		},
		'countRows': function(val) {
			if ( !this.isLoading ) {
				this.getOrganizations()
				localStorage.setItem("countRows", parseInt(val))
			}
		},
		'search': function() {
			this.getOrganizations()
		},
		'orgUchred': function() {
			this.dataEditOrg.uchred = this.orgUchred ? 'Государственный' : 'Негосударственный'
		},
	},
	methods: {
		getRumcs() {
			this.$http.get("rumcs", { params: {
				onlyRumcs: true
			} }).then((response) => {
				this.rumcs = response.data.items;
				this.rumcs.map((i) => (i.id = String(i.id)));
			});
		},

		// ? Сохранить изменения организации
		saveEditOrg() {
			if (this.$refs.editOrgForm.validate()) {
				this.$confirm.q(`Вы подтверждаете изменение организации #${this.dataEditOrg.id}?`, () => {
					this.$http.post(`organizations/${this.dataEditOrg.id}`,{
						form: this.dataEditOrg
					}).then((response) => {
						if ( response.data.status ) {
							this.$toast.open('Организация отредактирована');

							this.dialogEditOrg = false
							// ? Обновляем информацию в списке пользователей 
							setTimeout(() => {
								this.getOrganizations()
							},100)
						} else {
							this.$toast.error("Возникли ошибки при редактировании организации");
						}
					});
				}, () => {
					this.$toast.warning("Действие отменено пользователем");
				})
			}
		},

		closeEditOrg() {
			this.dialogEditOrg = false;
			this.$refs.editOrgForm.resetValidation();
		},

		// ? Получить информацию об организации для редактирования 
		openEditDialog( id ){
			this.btnLoadingEdit = id;
			this.idEditOrg = id;
			this.$http.get(`organizations/${id}`).then((response) => {
				this.dialogEditOrg = true;
				this.dataEditOrg = response.data.item;
				this.orgUchred = this.dataEditOrg.uchred == 'Государственный' ? true : false

				setTimeout(() => {
					this.btnLoadingEdit = 0
				},300);
			});
		},
		
		deleteOrg( id ) {
			this.$toast.warning(`Организация ${id} не может быть удалена. Действие заблокировано!`);
		},

		getOrganizations() {
			this.isLoading = true
			this.$http.get("organizations", { params: {
				count: this.countRows,
				page: this.page,
				search: this.search ? this.search : this.$route.query.search
			} }).then((response) => {
				this.orgs = response.data.items
				this.total = parseInt(response.data.options.total)
				this.page = parseInt(response.data.options.page)
				this.totalPage = parseInt(response.data.options.totalPage)

				this.regions = response.data.regions

				setTimeout(() => {
					this.isLoading = false
				}, 200)
			});
		},

		handleNewLogo() {
			// this.$toast.warning(`Функционал в разработке`);
			this.newLogoUrl = URL.createObjectURL(this.newLogo);
			// this.loadingNewLogo = true;
			// var data = new FormData();
			// data.append("logo", this.newLogo)
			// fetch("http://127.0.0.1:5050/logo", {
			// 	method: 'POST',
			// 	body: data
			// })
			// .then(response => response.blob())
			// .then(blob => {
			// 	var file = new File([blob], "tmp.png")
			// 	this.newLogo = file;
			// 	this.newLogoUrl = URL.createObjectURL(file);
			// 	this.loadingNewLogo = false;
			// })
			// .catch((error) => {
			// 	console.log("error", error);
			// 	this.$toast.error("Возникли ошибки при конвертации изображения в чёрно-белое. Попробуйте другое изображение или обратитесь в техническую поддержку");
			// 	this.loadingNewLogo = false;
			// });
		},

		saveNewLogo(org_id) {
			this.$confirm.q(`Логотип будет конвертирован в чёрно-белый. Страница будет перезагружена.`, () => {
				var logo = new FormData();
				logo.append("logo", this.newLogo)
				this.$http.post(`organizations/${org_id}/logo`, logo).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Логотип изменен');
						this.dialogEditLogo = false;
						
						// перезагрузка страницы для обновления логотипа в списке организаций
						// без обновления страницы логотип не меняется, вероятно потому, что не видит изменение поля в объекте (поля ссылки на лого)
						location.reload();
					} else {
						this.$toast.error("Возникли ошибки при редактировании логотипа");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		deleteLogo(org_id) {
			this.$confirm.q(`Логотип будет заменён на стандартный. Страница будет перезагружена.`, () => {
				this.$http.delete(`organizations/${org_id}/logo`).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Логотип изменен');
						this.dialogEditLogo = false;
						
						// перезагрузка страницы для обновления логотипа в списке организаций
						// без обновления страницы логотип не меняется, вероятно потому, что не видит изменение поля в объекте (поля ссылки на лого)
						location.reload();
					} else {
						this.$toast.error("Возникли ошибки при редактировании логотипа");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		openDialogGallery() {
			this.dialogOrgGallery = true;
		},

		openDialogBranches() {
			this.dialogBranches = true;
		},

		handleEditGallery(item) {
			this.loadingEditGallery = item.id;

			this.$http.put(`organizations/${this.dataEditOrg.id}/gallery/${item.id}`, {form: {name: item.name}}).then((response) => {
				if ( response.data.status ) {
					this.$toast.open('Подпись изменена');
					this.loadingEditGallery = 0;
				} else {
					this.$toast.error("Возникли ошибки при редактировании");
				}
			});
		},

		handleDeleteGallery(item) {
			this.$confirm.q('Удалить изображение?', () => {
				this.loadingDeleteGallery = item.id;
				this.$http.delete(`organizations/${this.dataEditOrg.id}/gallery/${item.id}`).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Изображение удалено');
						this.loadingDeleteGallery = 0;

						this.dataEditOrg.gallery = this.dataEditOrg.gallery.filter((i) => (i.id != item.id));
					} else {
						this.$toast.error("Возникли ошибки при редактировании");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		addToGallery() {
			if (this.newGallery) {
				this.loadingAddToGallery = true;
				var img = new FormData();
				img.append("img", this.newGallery)
				this.$http.put(`organizations/${this.dataEditOrg.id}/gallery`, img).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Изображение сохранено');
						var max_id = 0;
						for (let item of this.dataEditOrg.gallery) {
							if (item.id > max_id) { max_id = item.id }
						}
						this.loadingAddToGallery = false;
						this.dataEditOrg.gallery.push(response.data.items);
						this.newGallery = null;
					} else {
						this.$toast.error("Возникли ошибки при редактировании");
					}
				});
			}
		},

	},
	created(){
		const countRows = localStorage.getItem("countRows");
		if ( countRows && parseInt(countRows) > 0 ) {
			this.countRows = parseInt(countRows)
		}
		this.getOrganizations();

		this.getRumcs();
	}
}
</script>

<style>
.link-email { color:#1A237E; text-decoration: none !important; border-bottom: 1px dashed #1A237E;}
.pag-end .v-pagination { justify-content: right !important; }

.delete_img {
	position: absolute;
	top: 0;
	right: 0;
}
</style>